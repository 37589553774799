import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../../Store/auth-context";
import {
  LoginForm,
  sellerDetailsByMobile,
} from "../../../Services/UserService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../../API/axios";
import "./OndcLogin.scss";
import Spinner from "../../../Common/Loading/Spinner";
import showPass from "../../../../assets/images/icons/show-pass.png";
import hidePass from "../../../../assets/images/icons/hide-pass.png";
const KEY_FETCH_URL = "baas/common-service/generateAccessToken";

function LoginOndc({ setLocationPath }) {
  const toastId = React.useRef(null);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  // const [ondcUser, setondcUser] = useState(false)
  const ondcUser = "true";

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get("username");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const mobileRegex = /^\d{10}$/;

  const [ip, setIP] = useState("");
  useEffect(() => {
    getKeyMethod();
  }, []);

  const getKeyMethod = () => {
    axios
      .post(KEY_FETCH_URL)
      .then((data) => {
        sessionStorage.setItem("token", data.data.objectData.Token);
      })
      .catch((e) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please contact to Administrator.");
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 5000);
      });
  };

  // show hide password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submitData = (e) => {
    e.preventDefault();
    setError(true);
    const isMobileValid = /^[0-9]{10}$/.test(mobile);
    if (mobile.length === 10 && isMobileValid && password.length !== 0) {
      submitLogin();
    }
  };

  const submitLogin = async () => {
    setLoading(true);
    const info = {
      HEADER: {
        mobile: mobile,
      },
      DATA: {
        password: password,
      },
    };

    await LoginForm(info)
      .then((response) => {
        if (response.status === 200) {
          const formResponse = response.data;
          console.log(formResponse, "response");
          if (formResponse.RESP_CODE === 300) {
            sellerDetails();
            // setLoading(false);
            sessionStorage.setItem("mobile", mobile);
            // navigate("/seller/kyc");
          } else {
            setLoading(false);
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.error(formResponse.RESP_MSG);
            }
          }
        } else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(
              "Server issue, please try again later"
            );
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const sellerDetails = async () => {
    setLoading(true);
    const info = {
      HEADER: {
        mobile: mobile,
      },
      DATA: {},
    };

    await sellerDetailsByMobile(info)
      .then((response) => {
        if (response.status === 200) {
          const formResponse = response.data;
          console.log("formResponse", formResponse);
          if (formResponse.RESP_CODE === 300) {
            // setondcUser(true)
            const token = sessionStorage.getItem("token");
            const expirationTime = new Date(new Date().getTime() + 18000000); // one zero increased for testing, Dont push
            authCtx.login(token, expirationTime.toISOString());
            // setLoading(false);
            sessionStorage.setItem("sellerCode", formResponse.DATA.sellerCode);
            sessionStorage.setItem("ondcUser", "true");
            const sellerStep = formResponse.DATA.sellerDedupe;
            let redirectStep = "";
            let redirectSubStep = "";
            if (sellerStep.sellerApproveFlag === "N") {
              if (sellerStep.businessAddressFlag === "N") {
                redirectStep = "businessAddressFlag";
              } else if (sellerStep.bankDetailsFlag === "Y") {
                redirectStep = "sellerApproveFlag";
              } else if (sellerStep.storeDocumentFlag === "N") {
                if (sellerStep.aadharVerifiedFlag === "N") {
                  redirectStep = "storeDocumentFlag";
                  redirectSubStep = "aadharVerifiedFlag";
                } else if (sellerStep.panVerifiedFlag === "N") {
                  redirectStep = "storeDocumentFlag";
                  redirectSubStep = "panVerifiedFlag";
                } else if (sellerStep.gstVerifiedFlag === "N") {
                  redirectStep = "storeDocumentFlag";
                  redirectSubStep = "gstVerifiedFlag";
                } else if (sellerStep.fassiFlag === "N") {
                  redirectStep = "storeDocumentFlag";
                  redirectSubStep = "fassiFlag";
                }
              } else if (sellerStep.bankDetailsFlag === "N") {
                redirectStep = "bankDetailsFlag";
              } else {
                redirectStep = "sellerApproveFlag";
              }
              navigate("/seller/kyc", {
                state: { currentStep: redirectStep, subStep: redirectSubStep },
              });
            } else if (sellerStep.storeSettingFlag === "N") {
              if (sellerStep.storeHolidayFlag === "N") {
                if (sellerStep.storeTimingFlag === "N") {
                  if (sellerStep.sellerBusinessProfileFlag === "N") {
                    redirectStep = "sellerBusinessProfileFlag";
                  } else {
                    redirectStep = "storeTimingFlag";
                  }
                } else {
                  redirectStep = "storeHolidayFlag";
                }
              } else {
                redirectStep = "storeSettingFlag";
              }
              navigate("/seller/store-info", {
                state: {
                  currentStep: redirectStep,
                  subStep: redirectSubStep,
                },
              });
            } else if (sellerStep.storeSettingFlag === "Y") {
              redirectStep = "storeApprove";
              navigate("/seller/store-info", {
                state: {
                  currentStep: redirectStep,
                  subStep: redirectSubStep,
                },
              });
            }
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          alert("Server issue, please try again later"); // problem with server screen needed
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://geolocation-db.com/json/");
        setIP(response.data); // used when there was requirement for BAAS login API, current not being used
      } catch (error) {
        console.error("Error fetching IP:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    if (username) {
      setMobile(username); // if user is logging just after registration
    } else {
      setMobile("");
    }
  }, []);

  const preventCopyPaste = (e) => {
    e.preventDefault();
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Copy & Paste is disabled");
    }
  };
  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div id="login-box" className="col-12 col-sm-12 col-md-6">
            <form
              className="ondc-login-form"
              id="sigin-form"
              onSubmit={submitData}
            >
              <h2 className="welcome-text">Welcome</h2>
              <h4>Login here</h4>
              <div className="input">
                <div className="inputBox">
                  <label htmlFor="mobile">Mobile Number</label>
                  <input
                    type="text"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    onKeyDown={(e) => {
                      const allowedKeys = ["Backspace", "Delete"];
                      const isNumeric = /^[0-9]$/.test(e.key);
                      const isAllowedKey = allowedKeys.includes(e.key);

                      if (!isNumeric && !isAllowedKey) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={10}
                    id="mobile"
                    autoComplete="off"
                  />
                  {error && !mobileRegex.test(mobile) ? (
                    <span className="text-danger">
                      Please enter valid mobile number
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="inputBox ondc-login-pass">
                  <label htmlFor="pwd">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    onCopy={(e) => preventCopyPaste(e)}
                    onPaste={(e) => preventCopyPaste(e)}
                    onCut={(e) => preventCopyPaste(e)}
                    id="pwd"
                    autoComplete="off"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle"
                  >
                    {showPassword ? (
                      <img src={showPass} alt="show-pass" />
                    ) : (
                      <img src={hidePass} alt="hide-pass" />
                    )}
                  </span>
                  {error && password.length <= 0 ? (
                    <span className="text-danger">Please enter password</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="inputBox">
                  <button className="my-btn login-submit-btn">Submit</button>
                </div>
              </div>
              <p className="forget">
                <Link to="/baas/forget">Forgot Password?</Link>
              </p>
              <p className="seller-register">
                <Link
                  to="/baas/ONDC/registration"
                  onClick={() => {
                    setLocationPath("/baas/ONDC/registration");
                  }}
                >
                  Register as Seller ?
                </Link>
              </p>
            </form>
          </div>
        </>
      )}
      <ToastContainer />
    </Fragment>
  );
}

export default LoginOndc;
