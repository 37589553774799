import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sellerDetailsByMobile } from "../../Services/UserService";

function useKycDedupe() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dedupeRes, setDedupeRes] = useState();
  const userMobile = sessionStorage.getItem("mobile");

  const dedupCall = async () => {
    try {
      console.log("Dedup call >>>");
      setLoader(true);

      const info = {
        HEADER: {
          mobile: userMobile,
        },
        DATA: {},
      };

      const response = await sellerDetailsByMobile(info);

      if (response.status === 200) {
        const formResponse = response.data;

        if (formResponse.RESP_CODE === 300) {
          sessionStorage.setItem("sellerCode", formResponse.DATA.sellerCode);
          setDedupeRes(formResponse.DATA);
        } else {
          console.log(formResponse.RESP_MSG);
        }
      } else {
        alert("Server issue, please try again later");
      }

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   dedupCall();
  // }, [])

  return { dedupCall, loader, dedupeRes };
}

export default useKycDedupe;
