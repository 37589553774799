import React, { useRef } from 'react'
import "./Header.scss"
import Logo from "../../../images/easypayLogo.png";
import { Link, useLocation } from 'react-router-dom';
import { partnerDetails } from '../../../util/utils';

function Header({handleLocation ,baas }) {
  const location = useLocation()
  let domainDetail = partnerDetails()
  const headerRef = useRef(null);
  // Animated Header
  const changeHeader = () => {
    if (window.scrollY > 10) {
      headerRef.current &&
        (headerRef.current.style.boxShadow =
          "0px 1px 6px 0px rgba(32, 33, 36, 0.28)");
    } else {
      headerRef.current && (headerRef.current.style.boxShadow = "none");
    }
  };
  window.addEventListener("scroll", changeHeader);

  return (
    <header id="header" ref={headerRef}>
        <a className="sell-link" href="index.html">
          <img src={domainDetail?.partnerLogoPath || Logo} alt="logo" className="easypay-logo" />
        </a>
        <nav>
          <ul>
            <li className="register" onClick={handleLocation}>
              <Link to={(location.pathname ==="/baas/login" || location.pathname === "/baas/reset" || location.pathname === "/baas/forget") ? "/baas/registration" : (location.pathname ==="/baas/ONDC/login") &&  "/baas/ONDC/registration" } className="header-link">
                Register Here
              </Link>
            </li>
            <li className="stick"></li>
            <li onClick={handleLocation}>
              <Link to={(location.pathname === "/baas/registration" || location.pathname === "/baas/reset" || location.pathname === "/baas/forget") ? "/baas/login" : (location.pathname ==="/baas/ONDC/registration") && "/baas/ONDC/login" } id="login" className="header-link">
                Login
              </Link>
            </li>
          </ul>
        </nav>
      </header>
  )
}

export default Header