import './Footer.scss';
import easypaylogoblack from "../../../assets/images/easypaylogoblack.png";
import easypaylogowhite from "../../../assets/images/easypaylogowhite.png";

const Footer = ({myTheme})=> {

    return (
        <footer className="footer-bottom d-flex justify-content-end align-items-center">
            <div className="footer-site-info">
                Powered By 
                <img 
                    alt='easypay-logo' 
                    src={myTheme === "light" ? easypaylogoblack : easypaylogowhite} height="50"
                /> 
                © {new Date().getFullYear()}
            </div>
        </footer>
    );
}

export default Footer;