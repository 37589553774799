import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Bootstrap 5.2.0 which was installed is imported here
import "bootstrap/scss/bootstrap.scss";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./Store/auth-context";
import { Provider } from "react-redux";
import store from "./Store/store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthContextProvider>
  </Provider>
);

reportWebVitals();
