import React, { Fragment, useContext, useEffect, useState } from "react";
import "./Sidenav.scss";
import dashboard64 from "../../../assets/images/icons/dashboard/dashboard64.png";
import payin64 from "../../../assets/images/icons/pay-in/payin64.png";
import payout64 from "../../../assets/images/icons/pay-out/payout64.png";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

// import { useDedupCall } from "../../Common/CustomeHooks/useDedupCall";

import userLogo from "../../../assets/images/easypaylogoblack.png";
import useKycDedupe from "../../Common/CustomeHooks/useKycDedupe";
import AuthContext from "../../../Store/auth-context";
// import Spinner from "../../Common/Loading/Spinner";

const Sidenav = () => {
  // let userLogo = sessionStorage.getItem("partnerLogo");
  const navigate = useNavigate();
  const { dedupCall, loader } = useKycDedupe();
  // const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const [logo, setLogo] = useState(userLogo);
  const [logo2, setLogo2] = useState(userLogo);

  useEffect(() => {
    let submenus = document.getElementsByClassName("sub-menu-content");
    submenus?.[0]?.classList?.add("show");
  }, []);

  const openSubmenu = (e) => {
    e.currentTarget.nextSibling.classList.toggle("show");
  };

  // Close all sidenav submenu when clicked outside
  // window.addEventListener("click", (event) => {
  //   let sidenav = document.getElementById("sidenav");
  //   if (sidenav) {
  //     if (!event.target.closest(".nav-area")) {
  //       navArea();
  //     }
  //     if (!event.target.closest(".right-nav-toggler")) {
  //       sideNavArea(sidenav, event);
  //     }
  //   }
  // });

  const navArea = () => {
    let submenus = document.getElementsByClassName("sub-menu-content");
    for (const element of submenus) {
      let openSubmenu = element;
      if (openSubmenu.classList.contains("show")) {
        openSubmenu.classList.remove("show");
      }
    }
  };

  const sideNavArea = (sidenav, event) => {
    if (!event.target.closest(".open-submenu-btn")) {
      if (sidenav.classList.contains("right-nav")) {
        sidenav.classList.remove("right-nav");
      }
    }
  };

  const handleNavLinkClick = (event) => {
    if (!event.currentTarget.getAttribute("to")) {
      event.preventDefault();
      // Handle the click event for the NavLink with empty 'to' prop
      // Add your custom logic here
    }
  };

  const sellerDetails = async () => {
    // dedupCall();
  };

  useEffect(() => {
    const partnerLogo = sessionStorage.getItem("partnerLogo");
    const partnerLogo2 = sessionStorage.getItem("partnerLogo2");
    setLogo(partnerLogo || userLogo);
    setLogo2(partnerLogo2 || userLogo);
  }, [userLogo]);

  return (
    <Fragment>
      <nav id="sidenav">
        <div className="sidenav-brand-wrapper fixed-top d-none d-lg-flex justify-content-center align-items-center sidenav-mini">
          <Link to="/home">
            <div className="brand-logo">
              <img height="50" src={logo} alt="logo" />
            </div>
            <div className="brand-logo-mini">
            <img height="28" src={logo2} alt="logo" />
          </div>
          </Link>
        </div>
        <ul className="navbar-nav nav-area">
          <li className="nav-item">
            <NavLink to="/home" className="nav-link">
              <span className="menu-icon">
                <img width="20" height="20" src={dashboard64} alt="" />
              </span>
              <span className="menu-title">Home</span>
            </NavLink>
          </li>

          {/* <li className="nav-item">
          <NavLink to="/seller/kyc" className="nav-link">
            <span className="menu-icon">
              <img width="20" height="20" src={dashboard64} alt="" />
            </span>
            <span className="menu-title">KYC</span>
          </NavLink>
        </li> */}

          {/* <li className="nav-item">
            <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
              <span className="menu-icon">
                <img width="20" height="20" src={payin64} alt="" />
              </span>
              <span className="menu-title">
                Pay-Ins
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div className="sub-menu-content">
              <ul className="navbar-nav sub-menu">
                <li className="sub-nav-item sub-active">
                  <NavLink
                    to="/payIn/summary"
                    className="sub-nav-link"
                    state={{ data: "payIn" }}
                  >
                    Summary
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/payIn/transaction" className="sub-nav-link">
                    Transactions
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
              <span className="menu-icon">
                <img width="20" height="20" src={payout64} alt="" />
              </span>
              <span className="menu-title">
                Pay-Outs
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div className="sub-menu-content">
              <ul className="navbar-nav sub-menu">
                <li className="sub-nav-item">
                  <NavLink
                    to="/payOut/summary"
                    className="sub-nav-link"
                    state={{ data: "payOut" }}
                  >
                    Summary
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/payOut/wallet" className="sub-nav-link">
                    Wallet
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/payOut/beneficiary" className="sub-nav-link">
                    Beneficiary
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/payOut/transaction" className="sub-nav-link">
                    Transactions
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
              <span className="menu-icon">
                <img width="20" height="20" src={payout64} alt="" />
              </span>
              <span className="menu-title">
                Vendor
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div className="sub-menu-content">
              <ul className="navbar-nav sub-menu">
                <li className="sub-nav-item">
                  <NavLink to="/vendor/ledger" className="sub-nav-link">
                    Ledger
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
              <span className="menu-icon">
                <img width="20" height="20" src={payout64} alt="" />
              </span>
              <span className="menu-title">
                Banking
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div className="sub-menu-content">
              <ul className="navbar-nav sub-menu">
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Open Current Acount
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Account Overview
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Add Funds
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Transfer
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    QR Code
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Payments
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Statements
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Employee Saving Account
                  </NavLink>
                </li>
              </ul>
            </div>
          </li> */}

          <li className="nav-item">
            <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
              <span className="menu-icon">
                <img width="20" height="20" src={payout64} alt="" />
              </span>
              <span className="menu-title">
                Sell on ONDC
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div className="sub-menu-content">
              <ul className="navbar-nav sub-menu">
                <li className="sub-nav-item">
                  <NavLink to="/seller/dashboard" className="sub-nav-link">
                    Dashboard
                  </NavLink>
                </li>
                {authCtx.canProcessOrder === "N" && (
                  <li className="sub-nav-item">
                    <NavLink
                      to="/seller/kyc"
                      className="sub-nav-link"
                      // onClick={sellerDetails}
                    >
                      ONDC Registration
                    </NavLink>
                  </li>
                )}

                <li className="sub-nav-item">
                  <NavLink to="/seller/profile" className="sub-nav-link">
                    Profile
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/seller/product-list" className="sub-nav-link">
                    Product List
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/seller/add-product" className="sub-nav-link">
                    Add Product
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to="/seller/product-variant"
                    className="sub-nav-link"
                  >
                    Product Variant
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/seller/orders" className="sub-nav-link">
                    Orders
                  </NavLink>
                </li>
                {/* <li className="sub-nav-item">
                  <NavLink
                    to="/seller/offers"
                    className="sub-nav-link"
                  >
                    Offers
                  </NavLink>
                </li> */}
                <li className="sub-nav-item">
                  <NavLink
                    to="/seller/returns"
                    className="sub-nav-link"
                  >
                    Return Orders
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/seller/issues" className="sub-nav-link">
                    Issues & Grievances
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/seller/customers" className="sub-nav-link">
                    Customers
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/seller/buyers" className="sub-nav-link">
                    Buyer Activation
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to="/seller/logistic-partners"
                    className="sub-nav-link"
                  >
                    LSP Activation
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Payments & Refunds
                  </NavLink>
                </li>
                {/* <li className="sub-nav-item">
                  <NavLink
                    to="/seller/multi-stores"
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Multi Stores
                  </NavLink>
                </li> */}
                {/* <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Statements
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Employee Saving Account
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </li>

          {/* <li className="nav-item">
            <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
              <span className="menu-icon">
                <img width="20" height="20" src={payout64} alt="" />
              </span>
              <span className="menu-title">
                Loans & Credits
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div className="sub-menu-content">
              <ul className="navbar-nav sub-menu">
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Business Loans
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Credit Line
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Two wheeler & Car Loan
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Credit Cards
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Invoice Financing
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Manage Loans
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Interest Calculator
                  </NavLink>
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li className="nav-item">
          <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
            <span className="menu-icon">
              <img width="20" height="20" src={payout64} alt="" />
            </span>
            <span className="menu-title">
              Status
              <span id="down-arrow" className="arrow">
                <IoIosArrowDown />
              </span>
            </span>
          </div>
          <div className="sub-menu-content">
            <ul className="navbar-nav sub-menu">
              <li className="sub-nav-item">
                <NavLink
                  to="/status/transfer"
                  className="sub-nav-link"
                >
                  Transfer
                </NavLink>
              </li>
              <li className="sub-nav-item">
                <NavLink
                  to="/status/beneficiary"
                  className="sub-nav-link"
                >
                  Beneficiary
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}
        </ul>
      </nav>
    </Fragment>
  );
};

export default Sidenav;
