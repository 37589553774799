import { Outlet } from "react-router-dom";
import Header from "../Common/Header/Header";

const Baas = ({setLocationPath})=> {
    const handleLocation = () => {
        const currentLocation = window.location.pathname;
        setLocationPath(currentLocation);
      };
    return (
        <>
        <Header handleLocation={handleLocation} />
        <Outlet />
        </>
    )
}

export default Baas;