import './Spinner.scss';
import loader from '../../../assets/images/loader_easy_pay1.gif'
import { useEffect } from 'react';

export const SmallLoader = ({ loaderText }) => {
    return (
        <div className="small-loader">
            <span className="small-spinner"></span>
            {loaderText}
        </div>
    )
}


const Spinner = () => {
    useEffect(() => {
        if (document.body.style.overflow === "hidden") {
            return;
        } else {
            document.body.style.overflow = "hidden";
            return () => {
                document.body.style.overflow = "visible";
            }
        }
    }, [])

    return (
        <div className="spinner-container">
            <div className='loader'>
                <img src={loader} alt="easypay-loader" id="easypay-loader" />
            </div>
            {/* <div className="progress"></div> */}
        </div>

    );
}

export default Spinner;