import axios from "axios";
let token = sessionStorage.getItem('vendor_token');
const secureApiUrls = [
    'vendor-service/allVendorList',
    'vendor-service/getVendorDetails',
    'vendor-service/getVendorWalletBalance',
    '/vendor-service/vendorDetailsExport',
    "/vendor-service/sellerReport",
]

const apiAxios =  axios.create({
    baseURL: process.env.REACT_APP_PORTAL_URL,
    //headers: headers
});

apiAxios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if(secureApiUrls.includes(config.url) && token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default apiAxios;