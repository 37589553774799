import { configureStore } from "@reduxjs/toolkit";
import sellerReducer from "../reducers/sellerSlice";

const store = configureStore({
  reducer: {
    seller: sellerReducer,
  },
});

export default store;
