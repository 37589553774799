import React, { Fragment, useEffect, useState , useRef } from "react";
import { Link, Outlet} from "react-router-dom";
import Logo from "../../../assets/images/ondc/images/logo.png";
import featureImage from "../../../assets/images/ondc/icons/sell-account.png";
// import "../Authentication/Registration/Registration.scss";
import "./Authentication.scss";
import { ToastContainer } from "react-toastify";
import Header from "../../Common/Header/Header";
import { partnerDetails } from "../../../util/utils";



function Authentication({locationPath,setLocationPath}) {

const headerRef = useRef(null)
let domainDetail = partnerDetails()
const [isVisible, setIsVisible] = useState(false)

function handleFlip () {
  if(document.getElementById("login-box") !== null){
    document.getElementById("login-box").style.perspective = "1000px"
  }
  if(document.getElementById("singup-box") !== null){
    document.getElementById("singup-box").style.perspective = "1000px"
  }
  const registerFormElement = document.getElementById("registration-form");
  const siginFormElement = document.getElementById("sigin-form");
  if (locationPath === "/baas/ONDC/login") { 
    siginFormElement && (siginFormElement.style.transform = "rotateY(0deg)");
  } else if(locationPath === "/baas/ONDC/registration") {
    registerFormElement && (registerFormElement.style.transform = "rotateY(0deg)");
     }
}

const handleLocation = ()=>{
  const currentLocation = window.location.pathname;
  setLocationPath(currentLocation)
  // || locationPath === "/baas/ONDC/login" 
}

useEffect(()=>{
  const siginFormElement = document.getElementById("sigin-form");
  const registerFormElement = document.getElementById("registration-form");
  if(locationPath){
    setIsVisible(true)
  }
  if(isVisible){
    siginFormElement && (siginFormElement.style.transform ="rotateY(180deg)");
    siginFormElement && (siginFormElement.style.backfaceVisibility ="hidden");
    registerFormElement && (registerFormElement.style.transform ="rotateY(-180deg)");
    registerFormElement && (registerFormElement.style.backfaceVisibility ="hidden");
  }

    setTimeout(() => {
    handleFlip()
  }, 100);

},[locationPath])


useEffect(()=>{
const initialLocation = window.location.pathname;
  setLocationPath(initialLocation)
  // if(initialLocation === "/baas/ONDC/login" || initialLocation === "/baas/ONDC/registration" ){
  //   setIsVisible(true)
  // }
},[])


// Animated Header
const changeHeader = () => {
  if (window.scrollY > 10) {
    headerRef.current && (headerRef.current.style.boxShadow = '0px 1px 6px 0px rgba(32, 33, 36, 0.28)');
  } else {
    headerRef.current && (headerRef.current.style.boxShadow = 'none');
  }
};
window.addEventListener("scroll", changeHeader);





  return (
    <Fragment>
            <>
       {/* <header id="header" ref={headerRef}>
        <a className='sell-link' href="index.html">
            <img src={Logo} alt="easypay-logo" className="easypay-logo"/>
        </a>
        <nav>
            <ul >
                <li className="register" onClick={handleLocation}  >
                    <Link to="/baas/ONDC/registration" className="header-link">
                        Register Here
                    </Link>
                </li>
                <li className="stick"></li>
                <li onClick={handleLocation} >
                    <Link  to="/baas/ONDC/login" id="login" className="header-link">
                        Login
                    </Link>
                </li>
            </ul>
        </nav>
    </header> */}
    {/* <Header baas={"OndcBaas"} handleLocation={handleLocation}/> */}
      <div className="container login">
      <div className="row justify-content-center authetication-container" >
      <div className="col-12  col-sm-12 col-md-6 desc-pos ">
        {/* <div className="imgLogo">
          <img src={Logo} className="logo" alt=""></img>
        </div> */}
        <h4 className="left-content-title">
          Take your business to new customers on the ONDC network
        </h4>
        <div className="row feature-box">
          <div className="col-lg-6 col-md-6 feature-box-content">
            <img
              src={featureImage}
              alt="feature-imag"
              className="feature-img"
            />
            <div>
              <h5>More buyers</h5>
              <h6>Access millions of buyers across India</h6>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 feature-box-content">
            <img
              src={featureImage}
              alt="feature-imag"
              className="feature-img"
            />
            <div>
              <h5>Zero Cost</h5>
              <h6>No upfront cost to get started</h6>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 feature-box-content">
            <img
              src={featureImage}
              alt="feature-imag"
              className="feature-img"
            />
            <div>
              <h5>Easy To Use</h5>
              <h6>Start selling in no time</h6>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 feature-box-content">
            <img
              src={featureImage}
              alt="feature-imag"
              className="feature-img"
            />
            <div>
              <h5>Grow Your Business</h5>
              <h6>Promote your business with your own webpage</h6>
            </div>
          </div>
        </div>
        <div className="feature-hr"></div>
        <div className="pointer-feat">
          <h4 className="left-content-title">
            What you need to sell on {domainDetail?.partnerName}
          </h4>
          <ul>
            <li>GSTIN</li>
            <li>Bank Account</li>
          </ul>
        </div>
      </div>
      <Outlet/>
      {/* {
        isLoginPage ? 
        <div id='login-box' className="col-12 col-sm-12 col-md-6">
        <FormLogin/>
      </div> 
       :  <div id="singup-box" className="col-12 col-sm-12 col-md-6">
        <SingUp />
      </div>
      } */}
      </div>
       </div>
       </>
       {/* <ToastContainer/> */}
    </Fragment>
  );
}

export default Authentication;
