import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sellerDetailsByMobile } from "../../components/Services/UserService";

export const fetchSellerDetails = createAsyncThunk(
  "seller/fetchSellerDetails",
  async (info, { rejectWithValue }) => {
    try {
      const response = await sellerDetailsByMobile(info);
      if (response.status === 200 && response.data.RESP_CODE === 300) {
        return response.data.DATA;
      } else {
        return rejectWithValue(response.data.RESP_MSG || "Server error");
      }
    } catch (error) {
      return rejectWithValue(error.message || "Network error");
    }
  }
);

const sellerSlice = createSlice({
  name: "seller",
  initialState: {
    sellerData: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearSellerData(state) {
      state.sellerData = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSellerDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSellerDetails.fulfilled, (state, action) => {
        state.sellerData = action.payload;
        state.loading = false;
      })
      .addCase(fetchSellerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch seller details";
      });
  },
});

export const { clearSellerData } = sellerSlice.actions;

export default sellerSlice.reducer;
