import React, { Fragment } from 'react'
import "./LandingPage.scss"
import logo from "../../../assets/images/ondc/images/logo.png"
import heroCover from "../../../assets/images/ondc/images/hero_cover.png"
import ondcLogo from "../../../assets/images/ondc/images/ondc_logo.png"
import easypayOndc from "../../../assets/images/ondc/images/easypay_ondc.png"
import ondcFlow from "../../../assets/images/ondc/images/ondc_flow.png"
import ondcGifV1 from "../../../assets/images/ondc/images/ondc_gif_v1.gif"
import documentIcon from "../../../assets/images/ondc/icons/document-icon.svg"
import appIcon from "../../../assets/images/ondc/icons/apps-icon.svg"
import sellAccount from "../../../assets/images/ondc/icons/sell-account.png"
import orderIcon from "../../../assets/images/ondc/icons/orders-icon.svg"
import deliveryIcon from "../../../assets/images/ondc/icons/delivery-icon.svg"
import detailsIcon from "../../../assets/images/ondc/icons/details-icon.svg"
import sellList from "../../../assets/images/ondc/icons/sell-list.png"
import sellGetOrder from "../../../assets/images/ondc/icons/sell-get-order.png"
import sellDelivery from "../../../assets/images/ondc/icons/sell-delivery.png"
import sellPayment from "../../../assets/images/ondc/icons/sell-payment.png"
import laptopPerson from "../../../assets/images/ondc/images/laptop-person.png"
import sellOnOndc from "../../../assets/images/ondc/images/sell-on-ondc.jpg"
import onboardingDesktop from "../../../assets/images/ondc/images/sellers-onboarding-desktop.png"
import onboardingTablet from "../../../assets/images/ondc/images/sellers-onboarding-tablet.png"
import onboardingMobile from "../../../assets/images/ondc/images/sellers-onboarding-mobile.png"
import facebookIcon from "../../../assets/images/ondc/icons/facebook-icon.svg"
import instagramIcon from "../../../assets/images/ondc/icons/instagram-icon.svg"
import youtubeIcon from "../../../assets/images/ondc/icons/youtube-icon.svg"
import linkedinIcon from "../../../assets/images/ondc/icons/linkedIn-icon.svg"
import ISO from "../../../assets/images/ondc/images/iso.png"
import safetyLogo from "../../../assets/images/ondc/images/safety-logo.png"
import makeInIndia from "../../../assets/images/ondc/images/make_in_india.png"
import googlePlayBadge from "../../../assets/images/ondc/images/google-play-badge.png"
import { Link } from 'react-router-dom'
import { partnerDetails } from '../../../util/utils'

function LandingPage() {
  let domainDetail = partnerDetails()
  return (
    <Fragment>
        <div id="ondc-page">
         <header id="header">
        <a class='sell-link' href="index.html">
            <img src={logo} alt="easypay-logo" class="easypay-logo"/>
        </a>
        <nav>
            <ul >
                <li class="register">
                    {/* <a href="http://uat-baas.easypay.co.in/baas/registration" class="header-link" target="_blank"> */}
                    <Link to="/baas/ONDC/registration" class="header-link">
                        Register Here
                    </Link>
                </li>
                <li class="stick"></li>
                <li>
                    {/* <a href="http://uat-baas.easypay.co.in/baas/login" id="login" class="header-link" target="_blank"> */}
                    <Link to="/baas/ONDC/login" id="login" class="header-link">
                        Login
                    </Link>
                </li>
            </ul>
        </nav>
    </header>
  <section id="hero">
        <img id="hero-img" src={heroCover} alt="hero_img"/>
        <div id="hero-content">
            <h1 className="tagline">Bring your <span>business online</span> with <span>ONDC</span> network - a platform to
                democratize e-commerce in India.</h1>
            <a href="http://uat-baas.easypay.co.in/baas/registration" target="_blank" className="sell-link"
                style={{display:"none"}}>
                <button className="ondc-button">
                    Sell on ONDC
                    {/* <img src={arrowCircle} alt="arrow-circle"/> */}
                </button>
            </a>
        </div>
    </section>
    <section id="about-ondc">
        <h1 className="headline">About ONDC network</h1>
        <div className="content">
            <img className="desc-img" src={ondcLogo} alt="ondc_logo"/>
            <div className="content-text">
                ONDC (Open Network for Digital Commerce) , allows sellers to showcase products on various
                buyers platform. Buyer and seller can transact regardless of the platform as long as they are connected
                to
                the ONDC network.
            </div>
        </div>
    </section>
    <section id="easypay-ondc">
        <div className="content">   
            <h1 className="tabletline headline">{domainDetail?.partnerName} <span className="tag">- An ONDC network Participant</span></h1>
            <div className="img-container">
                <img className="desc-img" src={easypayOndc} alt="easypay_ondc"/>
            </div>
            <div className="content-text">
                <h1 className="headline">{domainDetail?.partnerName} <span className="tag">- An ONDC network Participant</span></h1>
                {domainDetail?.partnerName}, one of India’s leading digital payment platforms, has taken a significant step in enhancing
                the
                online
                experience with its latest integration called the Open Network for Digital Commerce (ONDC). <br/>This
                revolutionary
                feature is designed to streamline various services within the {domainDetail?.partnerName} app, making it easier than ever
                for
                users
                to access a multitude of offerings.
            </div>
        </div>
    </section>
    <section id="seller-work">
        <h1 className="headline">How ONDC network Works for sellers?</h1>
        <div className="content">
            <div className="gif-container">
                <img className="desc-img" src={ondcFlow} alt="ondc_gif"/>
                <img className="desc-img" src={ondcGifV1} alt="ondc_gif"/>
            </div>

            <div className="content-text">
                <div className="work">
                    <img src={documentIcon} alt="document-icon"/>
                    <p>List your products on a single platform</p>
                </div>
                <div className="work">
                    <img src={appIcon} alt="apps-icon"/>
                    <p>Get discovered on multiple buyer apps</p>
                </div>
                <div className="work">
                    <img src={orderIcon} alt="orders-icon"/>
                    <p>Get orders</p>
                </div>
                <div className="work">
                    <img src={deliveryIcon} alt="delivery-icon"/>
                    <p>Deliver through multiple partners</p>
                </div>
                <div className="work">
                    <img src={detailsIcon} alt="details-icon"/>
                    <p>Get customer details</p>
                </div>
            </div>
        </div>
    </section>
    <section id="benefits">
        <h1 className="headline">Benefits of onboarding to ONDC network via {domainDetail?.partnerName}</h1>
        <div className="card-container">
            <div className="ondc-card">
                <h3 className="head">One-click onboarding onto ONDC network</h3>
                <div className="content-text">Pre-register for ONDC network through quick and hassle free single-click
                    onboarding on
                    {domainDetail?.partnerName} App and become visible across all ONDC network partners.
                </div>
            </div>
            <div className="ondc-card">
                <h3 className="head">Multiple value-added services</h3>
                <div className="content-text">Register with {domainDetail?.partnerName} and connect with more than 100+ services for credit,
                    loans, digital payments, delivery and more.
                </div>
            </div>
            <div className="ondc-card">
                <h3 className="head">One-stop solution</h3>
                <div className="content-text">Get complete digital business solution: billing, catalog creation, B2B buying
                    & selling, pay-in , payouts, business reports and more.
                </div>
            </div>
        </div>
    </section>
    <section id="sell-on-ondc">
        <h1 className="headline">Sell On ONDC network with {domainDetail?.partnerName}</h1>
        <div className="content-text">
            With {domainDetail?.partnerName}, you can register as a seller and upload your catalogue. You will have a
            dedicated store on for your products that you can market to your buyers. Your catalog will also appear on
            ONDC network through other buyer apps like PayTm, PhonePe, Whatsapp, etc. {domainDetail?.partnerName} provides a complete
            catalogue panel to manage your products, orders, payments and payouts.
        </div>
        <div className="img-steps-container">
            <img src={sellOnOndc} className="sell-main-img" alt="sell-on-ondc"/>
            <div className="steps-container">
                <div className="step-container">
                    <img src={sellAccount} alt="" className="sell-icon"/>
                    <div className="step-row">
                        <span className="number">1</span>
                        <div className="step-info">
                            <span className="step-headline">Create your account</span>
                            <span className="step-details">Basic Information, Profession</span>
                        </div>
                    </div>
                </div>
                <div className="step-container">
                    <img src={sellList} alt="" className="sell-icon"/>
                    <div className="step-row">
                        <span className="number">2</span>
                        <div className="step-info">
                            <span className="step-headline">List your products</span>
                            <span className="step-details">Upload catalogue or add products individually</span>
                        </div>
                    </div>
                </div>
                <div className="step-container">
                    <img src={sellGetOrder} alt="" className="sell-icon"/>
                    <div className="step-row">
                        <span className="number">3</span>
                        <div className="step-info">
                            <span className="step-headline">Get orders</span>
                            <span className="step-details">Receive orders from all over india</span>
                        </div>
                    </div>
                </div>
                <div className="step-container">
                    <img src={sellDelivery} alt="" className="sell-icon"/>
                    <div className="step-row">
                        <span className="number">4</span>
                        <div className="step-info">
                            <span className="step-headline">Order fulfillment</span>
                            <span className="step-details">Collborate with delivery partner</span>
                        </div>
                    </div>
                </div>
                <div className="step-container">
                    <img src={sellPayment} alt="" className="sell-icon"/>
                    <div className="step-row">
                        <span className="number">5</span>
                        <div className="step-info">
                            <span className="step-headline">Get Payment</span>
                            <span className="step-details">Hassel free transactions</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </section>
    <section id="sms-adv">
        <div id="adv-background">
            <h1 className="headline">Seller Management Solutions for Wholesalers & Brands</h1>
            <div className="content-text">Enterprise seller management solutions provide wholesalers and brands with
                ready-to-use technology implementation needed to start selling through the ONDC network. Unlock growth
                for your brand with customizable, pre-built infrastructure to connect and manage your
                seller/dealer/distributor network on ONDC.</div>
            <a href="contact.html" target="_self" className="sell-link">
                <button className="ondc-button" id="ondc-contact-us">Contact Us</button>
            </a>

        </div>
    </section>
    <section id="sms">
        <h1 className="headline">Seller Management Solution</h1>
        <div className="content-text">Easy-to-use and feature-rich solutions to manage different sets of sellers or dealers
            for your brand with {domainDetail?.partnerName}. Easily manage your seller networks while enabling them to
            directly sell via ONDC. Make selling easy for your sellers and be in complete control of your seller
            network.</div>
        <div className="image-benefits-container">
            <div className="img-container">
                <img className="sms-img" src={laptopPerson} alt="laptop-person"/>
            </div>
            <ul className="benefits-container">
                <li className="benefit">Manage Sellers, Products and orders</li>
                <li className="benefit">Set and manage common settings (like time to ship, Pin code serviceability etc.) for
                    sellers</li>
                <li className="benefit">Set and manage Time Schedule (timings, holiday schedules etc.)</li>
                <li className="benefit">Manage Pay-in and payouts for sellers</li>
                <li className="benefit">Manage returnable, cancellable, invoice settings etc. for your seller network</li>
            </ul>
        </div>
    </section>
    <section id="invitation">
        <h1 className="headline">Make the transition to Open Networks</h1>
        <div className="content-text">Every day, new Seller, buyer and Logistics nodes are coming up on ONDC network. This
            allows
            you to expand your reach and make your network more resilient and vibrant by forming newer business
            partnerships rapidly.</div>
    </section>
    <section id="sellers-img-container">
        <picture>
            <source className="sellers-img" media="(min-width: 768px)"
                srcset={onboardingDesktop}/>
            <source className="sellers-img" media="(min-width: 425px)"
                srcset={onboardingTablet}/>
            <img className="sellers-img" src={onboardingMobile}
alt="sellers-onboarding-mobile"/>
        </picture>
    </section>
    <div class="footer-area">
        <section id="footer" class="footer-section">
          <div class="inner-footer-section">
            <div class="footer-links-container">
                <div class="footer-columns-container">
                    <div class="footer-column">
                        <h4>Pages</h4>
                        <a href="about.html" class="footer-link">About us</a>
                        <a href="partners.html" class="footer-link">Partners</a>
                        <a href="termsOfUse.html" class="footer-link">Terms of Use</a>
                        <a href="refundpolicy.html" class="footer-link">Refund Policy </a>
                        <a href="privacypolicies.html" class="footer-link">Privacy Policy</a>
                        <a href="contact.html" class="footer-link">Contact us</a>
                    </div>
                    <div class="footer-column">
                        <h4>Services</h4>
                        <a href="javascript:void(0);" onclick="redirectToIndexAndScrollTo('index.html', 'payment')" class="footer-link">Payments</a>
                        <a href="./loan/business-loan.html" class="footer-link">Lending</a>
                        <a href="javascript:void(0);" onclick="redirectToIndexAndScrollTo('index.html', 'services')" class="footer-link">Banking</a>
                    </div>
                    <div class="footer-column">
                        <h4>ONDC network </h4>
                        <a href="javascript:void(0);" onclick="redirectToIndexAndScrollTo('ondc.html', 'about-ondc')"
                            class="footer-link">About ONDC network</a>
                        <a href="javascript:void(0);" onclick="redirectToIndexAndScrollTo('ondc.html', 'sell-on-ondc')"
                            class="footer-link">Sell on ONDC network</a>
                        <a href="javascript:void(0);" onclick="redirectToIndexAndScrollTo('ondc.html', 'seller-work')"
                            class="footer-link">How it works?</a>
                        <a href="javascript:void(0);" onclick="redirectToIndexAndScrollTo('ondc.html', 'sms-adv')"
                            class="footer-link">Seller Management Solutions</a>
                    </div>
                </div>
                <div class="app-and-media">
                    <div class="social-media">
                        <a href="https://www.facebook.com/easypayindia/?ref=pages_you_manage" id="facebook" target="_blank"
                            class="footer-link"><img src={facebookIcon} alt="facebook"/></a>
                        <a href="https://www.instagram.com/easypayindia/?utm_medium=copy_link" id="instagram" target="_blank"
                            class="footer-link"><img src={instagramIcon} alt="instagram"/></a>
                        <a href="https://www.youtube.com/c/EasyPayIndia" id="youtube" target="_blank" class="footer-link"><img
                                src={youtubeIcon} alt="youtube"/></a>
                        <a href="https://www.linkedin.com/company/easypay-private-limited" id="linkedin" target="_blank"
                            class="footer-link"><img src={linkedinIcon} alt="linkedin"/></a>
                    </div>
                    <div class="download">
                        <h4>Download our app</h4>
                        <a
                            href="https://play.google.com/store/apps/details?id=aepsapp.paisanikal.com.aepsandroid&pcampaignid=web_share">
                            <img src={googlePlayBadge} id="download-app" alt="google-play-badge" srcset=""/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="disclaimer-container">
                <p>Disclaimer</p>
                <div>
                    All instances of "ONDC" and "Open Network For Digital Commerce" refer to "ONDC Network" and "ONDC
                    Protocol" and not ONDC the Section 8 company. {domainDetail?.partnerName} is one of the network participants of the ONDC
                    Network and offers Buyer and Seller apps in accordance with the ONDC Protocol.</div>
                </div>
          </div>
        </section >
        </div>
        <section class="copyright-section">
            <div class="copyright-row">
            <div class="certifications">
                <div class="certificate">
                    <img src={ISO} alt="iso-logo"/>
                </div>
                <div class="certificate">
                    <img src={safetyLogo} alt="safety-logo"/>
                </div>
                <div class="certificate">
                    <img src={makeInIndia} alt="made-in-india-logo"/>
                </div>
            </div>
            <div class="copyright-text-container">
                Copyright &copy;
                <script type="text/javascript">
                    document.write(new Date().getFullYear());
                </script> {domainDetail?.partnerName}
            </div>
        </div>
        </section>
        </div>
</Fragment>
  )
}

export default LandingPage